html {
  width: 100vw;


  scrollbar-width: none;

}

::-webkit-scrollbar {
  display: none;

}